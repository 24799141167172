import React, { useState, useMemo } from 'react'
import PropTypes from "prop-types"

import dayjs from "dayjs"

import config from "./data/config.js"
import { currentYear } from './data/currentYear.js'
const current = require(`./data/current-${currentYear}.js`)

import Util from './util.js'

const DataContext = React.createContext({})

export const DataContextProvider = ({ children }) => {
	// prepare event days (if available)
	let currentDayDefaults = {}
	const eventKeys = Object.keys(current.events)
	eventKeys.forEach(ek => {
		const currentEvent = current.events[ek]
		const lsKey = `${ek}-${currentYear}-day`

		let selectedDay = Util.getLSItem(lsKey)
		if (selectedDay) {
			let dayOK = true
			const days = Util.getEventDays(currentEvent)
			if (days) {
				const dayValidity = Util.getEventDayValidity(days, selectedDay)
				dayOK = dayValidity.ok
			}
			if (!dayjs(selectedDay).isValid() || !dayOK) selectedDay = null
		}

		const value = selectedDay || Util.getEventUpcomingDay(currentEvent)

		currentDayDefaults[ek] = value && dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : null
	})

	// finish up context prep
	const [currentDays, setCurrentDays] = useState(currentDayDefaults)

	const setCtxCurrentDay = (event, value) => {
		setCurrentDays(old => {
			return {
				...old,
				[event]: value
			}
		})
	}

	const getCtxCurrentDay = (event) => {
		return currentDays?.[event]
	}

	const ctxValue = useMemo(() => ({
		config,
		current,
		currentDays,
		setCtxCurrentDay,
		getCtxCurrentDay,
	}), [currentDays]) // eslint-disable-line

	return (
		<DataContext.Provider value={ctxValue}>
			{children}
		</DataContext.Provider>
	)
}

DataContextProvider.propTypes = {
	children: PropTypes.any,
}

export const useDataContext = () => React.useContext(DataContext)